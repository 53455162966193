<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active">
            <router-link :to="{ name: 'home' }">Home</router-link>
          </li>
          <li class="active"><router-link :to="{ name: 'dashboard' }">My Account</router-link></li>
          <li><a href="javascript:void(0);">Wishlist</a></li>
        </ul>
      </div>
    </div>
    <section class="section--wishlist">
      <div class="container">
        <h2 class="page__title">Wishlist</h2>
        <div class="wishlist__content">
          <div class="wishlist__product">
            <div class="wishlist__product--mobile">
              <div class="row m-0">
                <template v-if="isLoading">
                  <tr v-for="(i, index) in 6" :key="index">
                    <td><Skeletor /></td>

                    <td>
                      <div class="ps-product--vertical">
                        <Skeletor height="80" width="70" />
                        <div class="ps-product__content">
                          <h5>
                            <Skeletor />
                          </h5>
                          <p class="ps-product__unit"><Skeletor /></p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span class="ps-product__price"
                        ><Skeletor width="50"
                      /></span>
                    </td>
                    <td><Skeletor width="50" /></td>
                    <td><Skeletor width="150" height="30" /></td>
                  </tr>
                </template>

                <template v-if="lists.length">
                  <div
                    class="col-6 col-md-4 p-0"
                    v-for="(item, index) in lists"
                    :key="index"
                  >
                    <div class="ps-product--standard">
                      <a
                        class="ps-product__trash"
                        @click.prevent="removeItem(item.product.id)"
                      >
                        <i class="icon-trash2"></i>
                      </a>
                      <a href="#"
                        ><img
                          class="ps-product__thumbnail"
                          :src="item.product.base_image.small_image_url"
                          alt="alt"
                      /></a>
                      <div class="ps-product__content">
                        <p class="ps-product__instock">
                          <span
                            :class="
                              item.product.in_stock
                                ? 'ps-product__instock'
                                : 'ps-product__ofstock'
                            "
                            >{{
                              item.product.in_stock
                                ? "In stock"
                                : "Out of Stock"
                            }}</span
                          >
                        </p>
                        <a href="#">
                          <h5 class="ps-product__name">
                            {{ item.product.name }}
                          </h5>
                        </a>
                        <p class="ps-product__unit">{{ item.product.type }}</p>
                        <p class="ps-product__meta">
                          <span class="ps-product__price">{{
                            item.product.formated_price
                          }}</span>
                        </p>
                      </div>
                      <div class="ps-product__footer">
                        <router-link
                          class="btn wishlist__btn add-cart"
                          v-if="item.product.variants"
                          :to="{
                            name: 'product-view',
                            params: { productId: item.product.id },
                          }"
                        >
                          Addons
                        </router-link>
                        <button
                          class="ps-product__addcart"
                          :disabled="isLoadingProduct(item.product.id)"
                          v-else-if="item.product.in_stock"
                          @click="
                            addToCart({
                              product_id: item.product.id,
                              quantity: 1,
                            })
                          "
                        >
                          <i class="icon-cart"></i> Add to cart
                        </button>
                        <button class="btn wishlist__btn readmore" v-else>
                          Read more
                        </button>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                    <div class="col-12 p-10">
                      <p>Your wishlist items are empty.</p>
                    </div>
                </template>
              </div>
            </div>
            <div class="wishlist__product--desktop">
              <table class="table">
                <thead class="wishlist__thead">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Stock Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody class="wishlist__tbody">
                  <template v-if="isLoading">
                    <tr v-for="(i, index) in 2" :key="index">
                      <td><Skeletor /></td>

                      <td>
                        <div class="ps-product--vertical">
                          <Skeletor height="80" width="70" />
                          <div class="ps-product__content">
                            <h5>
                              <Skeletor />
                            </h5>
                            <p class="ps-product__unit"><Skeletor /></p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span class="ps-product__price"
                          ><Skeletor width="50"
                        /></span>
                      </td>
                      <td><Skeletor width="50" /></td>
                      <td><Skeletor width="150" height="30" /></td>
                    </tr>
                  </template>

                  <template v-if="lists.length">
                    <tr v-for="(item, index) in lists" :key="index">
                      <td>
                        <div class="wishlist__trash">
                          <a
                            href="javascript:void(0)"
                            @click.prevent="removeItem(item.product.id)"
                          >
                            <i class="icon-trash2"></i>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div class="ps-product--vertical">
                          <router-link :to="{ name: 'product-view', params: { productId: item.product.id }}">
                            <img
                              class="ps-product__thumbnail"
                              :src="item.product.base_image.small_image_url"
                              alt="alt" />
                          </router-link>
                          <div class="ps-product__content">
                            <h5>
                              <router-link class="ps-product__name"
                               :to="{ name: 'product-view', params: { productId: item.product.id }}"
                              >
                                {{ item.product.name }}
                              </router-link>
                            </h5>
                            <p class="ps-product__unit">
                              <strong>SKU: </strong>{{ item.product.sku }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span class="ps-product__price">{{
                          item.product.formated_price
                        }}</span>
                      </td>
                      <td>
                        <span
                          :class="
                            item.product.in_stock
                              ? 'ps-product__instock'
                              : 'ps-product__ofstock'
                          "
                          >{{
                            item.product.in_stock ? "In stock" : "Out of Stock"
                          }}</span
                        >
                      </td>
                      <td>
                        <router-link
                          class="btn wishlist__btn add-cart"
                          v-if="item.product.variants"
                          :to="{
                            name: 'product-view',
                            params: { productId: item.product.id },
                          }"
                        >
                          Addons
                        </router-link>
                        <button
                          class="btn wishlist__btn add-cart"
                          :disabled="isLoadingProduct(item.product.id)"
                          v-else-if="item.product.in_stock"
                          @click="
                            addToCart({
                              product_id: item.product.id,
                              quantity: 1,
                            })
                          "
                        >
                          <i class="icon-cart"></i>Add to cart
                        </button>
                        <button class="btn wishlist__btn readmore" v-else>
                          Read more
                        </button>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!lists.length && !isLoading">
                    <tr>
                      <td colspan="5">Your wishlist items are empty.</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import "vue-skeletor/dist/vue-skeletor.css";
import { Skeletor } from "vue-skeletor";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { Skeletor },
  computed: {
    ...mapGetters("wishlist", ["lists", "isLoading"]),
    ...mapGetters("product", ["isLoadingProduct"]),
  },
  methods: {
    ...mapActions("cart", ["addToCart"]),
    ...mapActions("wishlist", ["removeItem"]),
  },
};
</script>